<template>
  <div class="HCE">
    <Banner :banner="banner" />
    <section id="MCN" class="first_section">
      <div class="row col-9 mx-auto">
        <Info :data="MCN" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img :src="require('../assets/hce/01.jpg')" alt="" />
        </div>
      </div>
    </section>
    <section id="multimedia">
      <div class="row col-9 mx-auto">
        <Info :data="multimedia" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img :src="require('../assets/hce/02.jpg')" alt="" />
        </div>
      </div>
    </section>
    <section id="planning">
      <div class="row col-9 mx-auto">
        <Info :data="Planning" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img :src="require('../assets/hce/03.jpg')" alt="" />
        </div>
      </div>
    </section>
    <section id="rental">
      <div class="row col-9 mx-auto">
        <Info :data="rental" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img :src="require('../assets/hce/04.jpg')" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
import Banner from "@/components/Banner";
import Info from "@/components/Info";

export default {
  components: {
    Banner,
    Info,
  },
  data() {
    return {
      banner: {
        url: require("../assets/hce/banner.jpg"),
        text: {
          title: "HCE 娛樂",
          content:
            "翰成數位 HCE 直播產業基地占地 1314 坪，基地建置 10 間攝影棚，專攻直播短影音服務。10 間攝影棚包括：新聞棚、時尚棚、服飾棚、知識棚、訪談棚、直播電商棚、50人直播培訓棚、美食棚、品牌發佈會大棚。我們期許以 HCE 直播產業基地為一站式解決方案的綜合品牌核心，建構台灣直播短影音生態圈，孵化直播短影音專業人才。",
        },
      },
      MCN: {
        text: {
          title: "MCN、KOL合作",
          content: `成為廠商與KOL之間的橋樑，透過AI 數據技術達到最佳化網紅推薦，用數據打造精準，不管是想要成為網紅，還是想要透過網紅行銷的企業，這裡是你的最佳選擇。`,
        },
      },
      multimedia: {
        text: {
          title: "多媒體創作",
          content: `提供各種素材規劃製作服務以滿足多樣行銷需求，包括音樂創作及影音素材庫等，運用翰成數位專業團隊的多媒體整合行銷手法，為品牌創造更加乘價值。`,
        },
      },
      Planning: {
        text: {
          title: "企劃製作",
          content: `專業網路直播與行銷企劃團隊，企劃製作規劃流程，完美製作各項節目，建構台灣直播短影音生態圈、孵化直播短影音專業人才。`,
        },
      },
      rental: {
        text: {
          title: "直播攝影棚租賃",
          content: `專業攝影棚設備滿足直播、短影音拍攝等需求,提供各式風格背景，專業造型梳化間，獨立休息區，適合各種類型商業拍攝、錄影拍攝及活動舉辦。`,
        },
      },
    };
  },
};
</script>
<style lang="scss"></style>
