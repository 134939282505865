<template>
  <section class="banner">
    <img :src="banner.url" alt="" />
    <div class="col-xl-9 col-11 mx-auto banner_text">
      <div
        class="wow animate__animated animate__fadeInUp"
        data-wow-duration="2s"
      >
        <h1>{{ banner.text.title }}</h1>
        <p v-html="banner.text.content"></p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    banner: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.banner_text {
  padding: 5rem;
  position: absolute;
  top: 20vw;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 27px 34px 42px 0px rgb(0 0 0 / 20%);
  h1 {
    font-weight: 600;
    font-size: 3.3rem;
  }
  p {
    line-height: 2rem;
  }
}
.banner {
  position: relative;
  min-height: 50vh;
}
.banner img {
  width: 100%;
}
Info {
  margin: 1rem;
}
@media screen and( max-width: 989px) {
  .banner_text {
    padding: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
}
</style>
